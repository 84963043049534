
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { loginAuth } from "../api/loginApi";
import { ruta_externo_placa_entrega, ruta_home } from "../constants/constants-rutas-codigo";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import { cambiarColores, encriptar, guardarLocalStorage, limpiarLocalStorage } from "../resources/funciones";
import { CONST_CODIGO_PARAMETRO } from "../constants/constants-codigo";

//Resources 
import caching from "../resources/caching";

export const LoginAutohub = () => {

    const navigate = useNavigate();
    const [mostrarError, setMostrarError] = useState(false);
    const [mensaje, setMensaje] = useState("");

    const resetPassword = () => {
        //console.log("resetear password");
    };

    const {
        register,
        handleSubmit,
        watch,
        reset,
        control,
        setValue,
        formState: { errors },
    } = useForm({});


    const saveFileWrapper = (predefinedValue, saveFile = () => { }) => {
        return (file) => {
            saveFile(predefinedValue, file);
        };
    }

    const deleteFileWrapper = (predefinedValue) => {
        return () => {
            setValue(predefinedValue, null);
        };
    }

    const onLogin = async () => {
        const dataPOST = `{
            user: watch("Email"),
            password: watch("Password"),
            parametro: CONST_CODIGO_PARAMETRO
        }`

        const dataPOSTEncriptado = {
            body: encriptar(dataPOST)
        }
        // console.log('dataPOST', dataPOST)
        // const {status, message, data} = await loginAuth(dataPOST);
        const respuesta = await loginAuth(dataPOSTEncriptado);
        const { status, message, data } = respuesta
        // console.log(respuesta)
        if (status === 1) {

            guardarLocalStorage(data, "puser")


            const estilosCompania = data?.estilo;
            if (estilosCompania) {

                const colores = [
                    { name: '--color-fondo-menu-primario', color: estilosCompania?.fondo_menu },
                    { name: '--color-texto-nav-item', color: estilosCompania?.text_opciones },
                    { name: '--color-fondo-nav-item-activo', color: estilosCompania?.fond_opc_activo },
                    { name: '--color-texto-nav-item-activo', color: estilosCompania?.text_opc_activo },
                    { name: '--color-button-primario', color: estilosCompania?.boton_primario },
                    { name: '--color-button-primario-hover', color: estilosCompania?.boton_primario_activo },
                    { name: '--color-button-primario-text', color: estilosCompania?.text_boton_primario },
                    { name: '--color-button-secundario', color: estilosCompania?.boton_secundario },
                    { name: '--color-button-secundario-hover', color: estilosCompania?.boton_secundario_activo },
                    { name: '--color-button-secundario-text', color: estilosCompania?.text_boton_secundario },
                ];

                cambiarColores(colores);
            }


            navigate(ruta_externo_placa_entrega);
            caching()
        } else {
            setMostrarError(true);
            setMensaje(message);
            // console.log("mensaje", mensaje);
        }


    };

    useEffect(() => {
        limpiarLocalStorage("puser")
    }, [])


    return (
        <div className="container-fluid p-0 m-0 bg-gradient-2 " style={{ height: "85vh", background: "#03315c", width: "100hw" }}>

            <div className="container">
                <div className="col-12 col-md-12 d-flex justify-content-center align-items-center pt-5 m-0 p-0" >

                    <div className="row justify-content-center">
                        <div className="col-7 ">
                            <h1 className="d-flex justify-content-center text-white">
                                Entrega de
                            </h1>
                            <h1 className="d-flex justify-content-center text-white">
                                placas
                            </h1>
                        </div>
                        <div style={{ width: "350px" }}>
                            <div>
                                <img src="/images/loginplaca.png" width={"100%"} />
                            </div>
                            <form className="g-3 pt-5" onSubmit={handleSubmit(onLogin)}>
                                <h5 className="text-white text-center">Inicie Sesion</h5>
                                <div className="mb-3">
                                    <input
                                        type="text"
                                        placeholder="Usuario"
                                        autoFocus={true}
                                        className="form-control"
                                        id="exampleInputEmail1"

                                        {...register("Email", { required: true })}
                                    />
                                </div>
                                <div className="mb-3">
                                    <input
                                        type="password"
                                        placeholder="Contraseña"
                                        className="form-control"
                                        id="exampleInputPassword1"
                                        {...register("Password", { required: true })}
                                    />
                                </div>

                                <button type="submit" className="btn btn-light col-12" onClick={handleSubmit(onLogin)}>
                                    Login
                                </button>

                                {mostrarError && (
                                    <div className="row my-3">
                                        <div className="col-12">
                                            <Grid item xs={6}>
                                                <Alert severity="error" >
                                                    <div className="text-center">
                                                        {mensaje}
                                                    </div>
                                                </Alert>
                                            </Grid>
                                        </div>
                                    </div>
                                )}

                            </form>
                        </div>
                    </div>
                </div>



            </div>
        </div>
    );
};
